<template>
  <div class="carInfor">
    <nav-bar title="车辆信息" @click-left="$router.go(-1)" left-arrow v-if="isWeixin_status == false" />
    <field :disabled="ifInfor" v-model="plate_num" label="车牌号" placeholder="车牌号" />
    <field :disabled="ifInfor" v-model="oil_type" label="常用油品" placeholder="常用油品" />
    <field :disabled="ifInfor" v-model="volume" label="汽车排量" placeholder="汽车排量" />
    <field :disabled="ifInfor" v-model="brand" label="汽车品牌" placeholder="汽车品牌" />
    <field :disabled="ifInfor" v-model="car_type" label="车系(车型)" placeholder="车系(车型)" />
    <field :disabled="ifInfor" v-model="buy_time" label="车辆购买时间" placeholder="车辆购买时间" @focus="buyTime" />
    <field :disabled="ifInfor" v-model="safe_end" label="保险到期时间" placeholder="保险到期时间" @focus="expireTime"/>

    <popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate" @confirm="selectTime" @cancel="show = false"/>
    </popup>
    <vant-button type="info" size="large" class="margin-top-set" @click="addCartInfor" v-if="!ifInfor">提交</vant-button>
    <vant-button type="primary" size="large" class="margin-top-set" @click="editCartInfor" v-else>修改</vant-button>
  </div>
</template>

<script>
import time from "@/lin/filter/time"
import CreditShop from "@/api/creditshop";
import { NavBar, Field, Button, DatetimePicker, Popup } from "vant";
export default {
  components: {
    NavBar,
    Field,
    vantButton: Button,
    DatetimePicker,
    Popup
  },
  data() {
    return {
      ifInfor: false, // false 没有用户信息
      radio: "",
      checked: false,
      plate_num: "",
      oil_type: "",
      volume: "",
      brand: "",
      car_type: "",
      buy_time: "",
      safe_end: "",
      show: false,
      minDate: new Date(1949, 0, 1),
      maxDate: new Date(2050, 0, 1),
      currentDate: new Date(),
      selectedtime: 0 // 1代表购买时间，2代代表到期时间
    };
  },
  mounted() {
    this.getCartInfor();
  },
  methods: {
    editCartInfor() {
      // 编辑
      this.ifInfor = false
    },
    buyTime() {
      this.show = true;
      this.selectedtime = 1;
    },
    expireTime() {
      this.show = true;
      this.selectedtime = 2;

    },
    selectTime(value) {
      // 时间选定
      console.log(time.setTime(value.getTime()/1000))
      if (this.selectedtime == 1) {
        this.buy_time = time.setTime(value.getTime()/1000)
      } else {
        this.safe_end = time.setTime(value.getTime()/1000)
      }
      this.show = false;
    },
    async getCartInfor() {
      try {
        const res = await CreditShop.cartInfor();
        if(res.data == null||res.data == "") {
          this.ifInfor = false;
          this.$toast("请完善车辆信息")
        } else {
          this.ifInfor = true
          this.plate_num = res.data.plate_num;
          this.oil_type = res.data.oil_type
          this.volume = res.data.volume
          this.brand = res.data.brand
          this.car_type = res.data.car_type
          this.buy_time = res.data.buy_time
          this.safe_end = res.data.safe_end
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async addCartInfor() { //添加车辆信息
      try {
        const res = await CreditShop.addCartInfor(
          this.plate_num,
          this.oil_type,
          this.volume,
          this.brand,
          this.car_type,
          this.buy_time,
          this.safe_end
        );
        console.log(res);
        if (res.code == 200) {
          this.ifInfor = true
          this.$toast("编辑成功")
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carInfor {
  height: 100vh;
  background: #f3f3f3;

  .yihang {
    display: flex;
  }
  .interval {
    height: 10px;
  }

  .margin-top-set {
    margin-top: 20px;
  }
}
</style>

