import { Toast } from "vant";
let time = {
  setTime(fmt) {
    const time = new Date(fmt * 1000);
    const Y = time.getFullYear();
    const M = (time.getMonth() + 1).toString().padStart(2, "0");
    const D = time
      .getDate()
      .toString()
      .padStart(2, "0");
    // const h = time.getHours().toString().padStart(2, '0')
    // const m = time.getMinutes().toString().padStart(2, '0')
    // const s = time.getSeconds().toString().padStart(2, '0')
    return `${Y}/${M}/${D}`;
    // return `${Y}-${M}-${D} ${h}:${m}:${s}`
  },
  checkPhone(phone) {
    //过滤手机号
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
      Toast("手机号码有误，请重填");
      return false;
    } else {
      return true;
    }
  }
};

export default time;
